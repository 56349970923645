import React, { Component } from "react";
import Title from "./Title";
import FeaturesItems from "./FeaturesItems";

class Features extends Component {
  render() {
    return (
      <section
        className="best_screen_features_area features_area_pad"
        id="features"
      >
        <svg xmlns="http://www.w3.org/2000/svg">
          <path
            fill="#fff"
            id="down_bg_copy_2"
            data-name="down / bg copy 2"
            className="cls-1"
            d="M444.936,252.606c-148.312,0-305.161-29.63-444.936-80.214V0H1920V12S808.194,234.074,444.936,252.606Z"
          />
        </svg>
        <ul className="memphis-parallax hidden-xs hidden-sm white_border">
          <li data-parallax='{"x": -00, "y": 100}'>
            <img
              className="br_shape"
              src={require("../images/line/f_l_01.png")}
              alt="f_img"
            />
          </li>
          <li data-parallax='{"x": 200, "y": 200}'>
            <img
              className="br_shape"
              src={require("../images/line/f_l_02.png")}
              alt="f_img"
            />
          </li>
          <li data-parallax='{"x": 150, "y": 150}'>
            <img
              className="br_shape"
              src={require("../images/line/f_l_03.png")}
              alt="f_img"
            />
          </li>
          <li data-parallax='{"x": 50, "y": 50}'>
            <img
              className="br_shape"
              src={require("../images/line/f_l_05.png")}
              alt="f_img"
            />
          </li>
          <li data-parallax='{"x": 150, "y": 150}'>
            <img src={require("../images/line/f_l_06.png")} alt="f_img" />
          </li>
          <li data-parallax='{"x": 100, "y": 100}'>
            <img src={require("../images/line/f_l_07.png")} alt="f_img" />
          </li>
          <li data-parallax='{"x": 50, "y": 50}'>
            <img src={require("../images/line/f_l_08.png")} alt="f_img" />
          </li>
          <li data-parallax='{"y": 250}'>
            <img src={require("../images/line/f_l_09.png")} alt="f_img" />
          </li>
          <li data-parallax='{"x": 250, "y": 250}'>
            <img src={require("../images/line/f_l_10.png")} alt="f_img" />
          </li>
          <li data-parallax='{"x": 150, "y": 150}'>
            <img
              className="br_shape"
              src={require("../images/line/f_l_04.png")}
              alt="f_img"
            />
          </li>
        </ul>
        <div className="container">
          <Title
            Stitle="Why Hire Students?"
            pfont="“Providing Internship Opportunities… Changes the Whole Equation.”—Eduardo J. Padrón"
          />
          <div className="row ">
            <div className="col-lg-4">
              <div className="b_screen_img wow fadeInUp">
                <img
                  src={require("../images/jude/studentsIcon.png")}
                  alt="featured"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="row b_features_info">
                <FeaturesItems
                  ftitle="Affordability"
                  text="Hiring students is considered another way to cut costs."
                  name="money"
                />
                <FeaturesItems
                  ftitle="Fresh Insights"
                  text="Students bring new perspectives, skills, and ideas."
                  name="light-bulb"
                />
                <FeaturesItems
                  ftitle="Return on Investment"
                  text="Most students join their first jobs with great enthusiasm."
                  name="stats-up"
                />
                <FeaturesItems
                  ftitle="Better Workforce"
                  text="Students teach new skills to their current employees."
                  name="briefcase"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Features;
