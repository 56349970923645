import React, { Component } from "react";
import Title from "./Title";
import PriceItem from "./PriceItem";

class Price extends Component {
  constructor() {
    super();
    this.state = {
      posts: [
        {
          id: 100,
          ptitle: "Free Trial",
          info: "Get to know us",
          rate: " 3 Month FREE Trial",
          list1: "1 Job Offer per month",
          list2: "1 Project per month",
          list3: "Full Access to students database",
          list4: "0 Personality Tests",
        },
        {
          id: 101,
          ptitle: "Standard",
          info: "Most popular choice",
          rate: "$1300/mxn Monthly",
          list1: "4 Job Offer /month",
          list2: "4 Project /month",
          list3: "Full Access to students database",
          list4: "0 Personality Tests",
        },
        {
          id: 102,
          ptitle: "Pro",
          info: "Get extra features",
          rate: "$1800/mxn Monthly",
          list1: "4 Job Offer /month",
          list2: "∞ Project /month",
          list3: "Full Access to students database",
          list4: "2 Personality Tests /month",
        },
      ],
    };
  }
  render() {
    return (
      <section className="priceing_area_two" id="pricing">
        <div className="container">
          <Title
            Stitle="Pricing Table For Companies"
            pfont="Different Prices for Start Ups, get a quote below"
          />
          <div className="row">
            {this.state.posts.map((post) => (
              <PriceItem key={post.id} post={post} image="price-icon1.png" />
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default Price;
