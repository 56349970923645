import React, { Component } from "react";

class Action extends Component {
  render() {
    let imgUrl = require("../images/get_bg.jpg");
    const divStyle = {
      background:
        "linear-gradient(0deg, rgba(76,132,255,0.88), rgba(76,132,255,0.88)), url(" +
        imgUrl +
        ") no-repeat",
    };
    return (
      <section className="new_call_action_area" style={divStyle} id="download">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-5 col-sm-12 col-xs-12">
              <div className="action_mobile wow fadeInUp">
                <img src={require("../images/jude/judeLogo.png")} alt="f_img" />
              </div>
            </div>
            <div className="col-lg-8 d_flex col-md-7 col-sm-12 col-xs-12">
              <div className="n_call_action_content">
                <h2 className="wow fadeInUp"> Join Jude Now! </h2>
                <div className="wow fadeInUp" data-wow-delay="300ms">
                  <p>
                    Jude®️ is the only digital platform for professional
                    engagement, which accelerates the process of connecting
                    university students with a current vision of the world and
                    committed to companies that wish to develop and drive their
                    innovation process in Mexico.
                  </p>
                </div>
                <a
                  href="https://platform.jude.com.mx/signup"
                  className="btn btn-normal apps-button wow fadeInUp"
                  data-wow-delay="450ms"
                >
                  <i className="fa fa-mouse-pointer"></i>{" "}
                  <span>Find a job today</span>
                  <br /> Sign Up{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Action;
