import React, { Component } from "react";
import { Link } from "react-router-dom";

class CustomNavbar extends Component {
  render() {
    return (
      <nav
        id="fixed-top"
        className="navbar navbar-toggleable-sm transparent-nav navbar-expand-lg"
      >
        <div className="container">
          <a className="nav-link" href="#home">
            <span className="navbar-brand">
              <img src={require("../images/jude/footer-logo.png")} alt="logo" />
              <img src={require("../images/jude/miniLogo.png")} alt="logo" />
            </span>
          </a>
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item dropdown submenu">
                <a
                  className="nav-link dropdown-toggle active"
                  href="#home"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#about_area">
                  What we do
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#faq">
                  FAQ
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#students">
                  For Students
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#features">
                  For Companies
                </a>
              </li>
            </ul>
            <a href="https://platform.jude.com.mx/login" className="get-btn">
              Sign In
            </a>

            <a href="https://platform.jude.com.mx/signup" className="get-btn">
              Sign Up Students
            </a>
          </div>
        </div>
      </nav>
    );
  }
}

export default CustomNavbar;
