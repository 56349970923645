import React, { Component } from "react";

class Forms extends Component {
  render() {
    let imgUrl = require("../images/get_bg.jpg");
    const divStyle = {
      background:
        "linear-gradient(0deg, rgba(76,132,255,0.88), rgba(76,132,255,0.88)), url(" +
        imgUrl +
        ") no-repeat",
    };
    return (
      <section className="new_call_action_area" style={divStyle} id="download">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-5 col-sm-12 col-xs-12">
              <div className="action_mobile wow fadeInUp">
                <img src={require("../images/jude/judeLogo.png")} alt="f_img" />
              </div>
            </div>
            <div className="col-lg-8 d_flex col-md-7 col-sm-12 col-xs-12">
              <div className="n_call_action_content">
                <h2 className="wow fadeInUp"> Get a Quote! </h2>
                <div className="wow fadeInUp" data-wow-delay="300ms">
                  <form>
                    <div className="wow fadeInUp"> Email </div>
                    <input
                      placeholder="Enter your email"
                      style={{
                        padding: "10px",
                        width: "300px",
                      }}
                    />
                    <div
                      style={{
                        padding: "10px",
                      }}
                    />
                    <div className="wow fadeInUp"> Subject </div>
                    <input
                      placeholder="Enter the Subject"
                      style={{
                        padding: "10px",
                        width: "300px",
                      }}
                    />
                    <br />
                    <div
                      style={{
                        padding: "10px",
                      }}
                    />
                    <textarea
                      placeholder="Message"
                      style={{
                        padding: "10px",
                        height: "200px",
                        width: "600px",
                      }}
                    />
                  </form>
                </div>
                <div
                  style={{
                    padding: "5px",
                  }}
                />
                <a
                  href="."
                  className="btn btn-normal apps-button wow fadeInUp"
                  data-wow-delay="450ms"
                  style={{
                    padding: "15px",
                  }}
                >
                  Send Email
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Forms;
