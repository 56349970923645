import React, { Component } from "react";
import Title from "../Title";
import $ from "jquery";

class Faq extends Component {
  componentDidMount() {
    $(".faq_accordian_two .card").click(function () {
      $(".faq_accordian_two .card").removeClass("active");
      $(this).addClass("active");
    });
  }
  render() {
    return (
      <section className="faq_solution_area features_area_pad" id="faq">
        <ul className="memphis-parallax hidden-xs hidden-sm white_border">
          <li data-parallax='{"x": -100, "y": 100}'>
            <img
              className="br_shape"
              src={require("../../images/line/fq_line-1.png")}
              alt="f_img"
            />
          </li>
          <li data-parallax='{"x": -200, "y": 200}'>
            <img
              className="br_shape"
              src={require("../../images/line/fq_line-2.png")}
              alt="f_img"
            />
          </li>
          <li data-parallax='{"x": -150, "y": 150}'>
            <img
              className="br_shape"
              src={require("../../images/line/fq_line-3.png")}
              alt="f_img"
            />
          </li>
          <li data-parallax='{"x": 50, "y": -50}'>
            <img
              className="br_shape"
              src={require("../../images/line/fq_line-4.png")}
              alt="f_img"
            />
          </li>
          <li data-parallax='{"x": -150, "y": 150}'>
            <img src={require("../../images/line/fq_line-5.png")} alt="f_img" />
          </li>
          <li data-parallax='{"x": -100, "y": 100}'>
            <img src={require("../../images/line/fq_line-6.png")} alt="f_img" />
          </li>
          <li data-parallax='{"x": 50, "y": -50}'>
            <img src={require("../../images/line/fq_line-7.png")} alt="f_img" />
          </li>
          <li data-parallax='{"x": 250, "y": -250}'>
            <img src={require("../../images/line/fq_line-8.png")} alt="f_img" />
          </li>
          <li data-parallax='{"x": 150, "y": -150}'>
            <img src={require("../../images/line/fq_line-9.png")} alt="f_img" />
          </li>
          <li data-parallax='{"y": -180}'>
            <img
              src={require("../../images/line/fq_line-10.png")}
              alt="f_img"
            />
          </li>
        </ul>
        <div className="container">
          <Title
            Stitle="FAQ"
            pfont="Any questions not answered here feel free to contact our instagram account @judeplatform or send an email at team@jude.com.mx"
          />
          <div className="row ">
            <div className="col-md-6 d_flex">
              <div id="accordion" className="faq_accordian_two">
                <div className="card wow fadeInUp active">
                  <div className="card-header" id="heading1">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target="#collapse0"
                        aria-expanded="true"
                        aria-controls="collapse0"
                      >
                        <i className="ti-id-badge"></i>Should I put my personal
                        info in my CV?{" "}
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse0"
                    className="collapse show"
                    aria-labelledby="heading1"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        No, for your privacy we suggest not to put contact
                        information in your CV. Jude will send you an email when
                        a Company is interested in you or when your request was
                        accepted
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card wow fadeInUp ">
                  <div className="card-header" id="heading2">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target="#collapse1"
                        aria-expanded="true"
                        aria-controls="collapse1"
                      >
                        <i className="ti-user"></i>Do I need a photo and CV to
                        sign up?{" "}
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse1"
                    className="collapse "
                    aria-labelledby="heading2"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        No, you can sign up with no CV and no Photo too see our
                        current Job Offers but what companies are most
                        interested in seeing is the students CV
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card wow fadeInUp ">
                  <div className="card-header" id="heading3">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target="#collapse2"
                        aria-expanded="true"
                        aria-controls="collapse2"
                      >
                        <i className="ti-book"></i>What if my major is not
                        available?{" "}
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse2"
                    className="collapse "
                    aria-labelledby="heading3"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Send us a dm or mail and we will add it right away so
                        you can find your intership
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card wow fadeInUp ">
                  <div className="card-header" id="heading4">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target="#collapse3"
                        aria-expanded="true"
                        aria-controls="collapse3"
                      >
                        <i className="ti-location-arrow"></i>Where is Jude
                        available?{" "}
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse3"
                    className="collapse "
                    aria-labelledby="heading4"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        In Monterrey, Nuevo Leon and Tampico, Tamaulipas but we
                        are looking for other places to start helping students
                        find their dream interships
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="faq_image_mockup wow fadeInUp"
                data-wow-delay="200ms"
              >
                <img
                  src={require("../../images/jude/FAQ-vector.png")}
                  alt="featured"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Faq;
