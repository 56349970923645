import React, { Component } from "react";
// import { Link } from "react-router-dom";
class BannerContent extends Component {
  render() {
    var { name, topic, topic2 } = this.props;
    return (
      <React.Fragment>
        <div className="n_banner_content">
          <h2 className="wow fadeInUp" data-wow-delay="300ms">
            {name}
          </h2>
          <p className="wow fadeInUp" data-wow-delay="500ms">
            {topic}
            <br /> {topic2}
          </p>
          <a href="https://platform.jude.com.mx/signup">
            <span className="n_banner_btn wow fadeInUp" data-wow-delay="600ms">
              Sign Up to Find an Internship
            </span>
          </a>
        </div>
      </React.Fragment>
    );
  }
}

export default BannerContent;
